import React, { useState, useEffect, useCallback } from 'react';
import { ArrowUp } from 'lucide-react';

const ScrollToTopButton = ({ threshold = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);

  // Show button when footer is visible or user has scrolled significantly
  const toggleVisibility = useCallback(() => {
    // Get footer element
    const footer = document.querySelector('footer');
    
    // Get current scroll position using modern properties
    const scrollY = window.scrollY || document.documentElement.scrollTop;
    
    if (!footer) {
      // If footer isn't found, fall back to threshold-based visibility
      setIsVisible(scrollY > threshold);
      return;
    }
    
    // Check if we're near the footer OR we've scrolled past the threshold
    const footerRect = footer.getBoundingClientRect();
    const isFooterNearOrVisible = footerRect.top < window.innerHeight * 1.2;
    const hasScrolledFarEnough = scrollY > window.innerHeight;
    
    setIsVisible(isFooterNearOrVisible || hasScrolledFarEnough);
  }, [threshold]);

  // Set up the scroll event listener
  useEffect(() => {
    window.addEventListener('scroll', toggleVisibility);
    // Initial check
    toggleVisibility();
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, [toggleVisibility]);

  // Smooth scroll to top function
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <button
        onClick={scrollToTop}
        className={`fixed bottom-6 left-6 z-50 p-3 rounded-full bg-primary text-white shadow-lg 
                  hover:bg-primary-hover transition-all duration-300 transform 
                  hover:-translate-y-1 hover:shadow-xl focus:outline-none 
                  focus:ring-2 focus:ring-primary focus:ring-opacity-50
                  ${isVisible 
                    ? 'opacity-100 translate-y-0' 
                    : 'opacity-0 translate-y-10 pointer-events-none'}`}
        aria-label="Scroll to top"
      >
        <ArrowUp className="w-5 h-5" />
      </button>
    </>
  );
};

export default ScrollToTopButton;